<template>
    <div
        class="user-data grid items-center relative"
        :class="isSettings ? 'settings' : ''"
        ref="user_data"
    >
        <div
            class="logo"
            :class="[
                isDisabled ? '!cursor-not-allowed' : '',
                isSettings ? 'settings' : '',
            ]"
            @click="toggleMenu"
        >
            <img v-if="logo" :src="logo" />
            <IconBase v-else icon="shopping-store" />
        </div>
        <div
            v-if="!multiLocation.isActive"
            class="data grid grid-rows-2"
            :class="isDisabled ? '!cursor-not-allowed' : ''"
            @click="toggleMenu"
        >
            <div class="name" :class="isSettings ? 'settings' : ''">
                {{ name }}
            </div>
            <div class="id" :class="isSettings ? 'settings' : ''">
                {{ id }}
            </div>
        </div>
        <SelectComponent
            style="min-width: 12rem; margin-left: 1.5rem"
            v-if="multiLocation.isActive"
            :options="multiLocation.locationsForDropdown"
            :value="multiLocation.selected_location_id"
            @update:value="multiLocation.swapLocation($event)"
        />
        <IconBase
            icon="chevron-down"
            @click="toggleMenu"
            v-if="!isDisabled && !multiLocation.isActive"
            :class="isSettings ? 'text-white' : ''"
        />
        <div class="user-menu absolute" v-if="open">
            <div
                class="menu-item"
                v-if="userStore.user.is_admin"
                @click="openAdminInfo"
            >
                <IconBase icon="key"></IconBase>
                <span class="text">View Admin Info</span>
            </div>
            <div
                class="menu-item"
                v-for="menuItem in menuItems"
                :key="menuItem.name"
                @click="goTo(menuItem.name)"
            >
                <IconBase :icon="menuItem.icon"></IconBase>
                <span class="text">{{ translate(menuItem.text) }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { useUserStore } from "@/store/User"
import IconBase from "@/ui-elements/IconBase.vue"
import { useClickAway } from "@/utils/useClickAway"
import { useI18n } from "vue-i18n"
import { RouteRecordRaw, useRouter } from "vue-router"
import { useSplitPaymentStore } from "@/store/SplitPayment"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { makeSrc } from "@/ui-elements/file-browser/useFileBrowser"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
import { offlineModeStore } from "@/store/offlineMode"
import SelectComponent, {
    SelectOption,
} from "@/ui-elements/input/select/SelectComponent.vue"
import { useFiltering } from "@/utils/useFiltering"
import { multiLocationStore } from "@/store/MultiLocation"
import { ClientLocation } from "@/interface/user/User"

export default defineComponent({
    name: "UserData",
    components: { IconBase, SelectComponent },
    props: {
        isSettings: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const isOffline = offlineModeStore().isOffline
        const { t: translate } = useI18n()
        const router = useRouter()
        const userStore = useUserStore()
        const user_data = ref()
        const open = ref(false)
        const name = computed(
            () =>
                useUserStore().user.address.company ||
                useUserStore().user.billing_address.company ||
                useUserStore().user.address.contact
        )
        const id = computed(() => "#" + useUserStore().user.id)
        const logo = computed(() => useUserStore().user.logo_url)
        useClickAway(user_data, () => (open.value = false))
        const splitPaymentStore = useSplitPaymentStore()
        const toggleMenu = () => {
            if (useUserStore().user.is_admin) {
                navigator.clipboard.writeText(
                    `#${useUserStore().user.id} | ${
                        useUserStore().user.address.company
                    }`
                )
                setToastNotification(
                    translate("success"),
                    translate("client_name_and_id_copied_to_clipboard"),
                    "success"
                )
            }

            return isDisabled.value ? null : (open.value = !open.value)
        }

        const menuItems = computed(() => [
            {
                text: translate("account"),
                name: { path: "/settings/general/account" },
                icon: "cog",
            },
            {
                text: translate("logout"),
                name: { name: "logout" },
                icon: "unlock",
            },
        ])

        const goTo = (route: RouteRecordRaw) => router.push(route)
        const openAdminInfo = () => {
            useUserStore().toggleAdminInfoModal()
            open.value = false
        }

        const isDisabled = computed(
            () => isOffline || useSplitPaymentStore().isPartiallyPaid
        )

        return {
            isOffline,
            name,
            id,
            logo,
            open,
            user_data,
            menuItems,
            translate,
            router,
            goTo,
            userStore,
            openAdminInfo,
            splitPaymentStore,
            toggleMenu,
            isDisabled,
            multiLocation: multiLocationStore(),
        }
    },
})
</script>

<style lang="scss">
.user-data {
    grid-template-columns: 2.75rem minmax(0, 1fr) 1rem;
    margin-left: $margin-l;
    //max-width: 14rem;
    &::after {
        position: absolute;
        content: "";
        background: $light-gray;
        width: 1px;
        height: 90%;
        top: 50%;
        left: -$margin-l;
        transform: translateY(-50%);
    }
    &.settings::after {
        content: none !important;
    }
    .logo,
    .data,
    .icon-chevron-down {
        user-select: none;
        cursor: pointer;
        &.text-white {
            color: $white;
        }
    }
    .logo {
        background: $light-gray;
        border-radius: $radius;
        padding: $padding-s;
        .icon-base {
            font-size: 1.75rem;
        }

        &.settings {
            background-color: rgba($light-gray, 0.2);
        }
    }
    .data {
        padding: 0 $padding-m;
        .name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.settings {
                color: $white;
            }
        }
        .id {
            color: $dark-gray;
            &.settings {
                color: $white;
            }
        }
    }
    .icon-chevron-down::before {
        font-weight: $font-weight-medium;
    }
    .user-menu {
        background: $white;
        left: 0;
        top: calc(100% + 1rem);
        padding: $padding-s $padding-m;
        border: $thick-important-border;
        border-radius: $radius;
        display: flex;
        flex-flow: column nowrap;
        box-shadow: $shadow;
        z-index: 20;

        &:before {
            content: " ";
            background: inherit;
            border-color: inherit;
            border-width: 2px 0 0 2px;
            width: 1.1rem;
            height: 1.1rem;
            transform: scale(0.6, 1) rotate(45deg);
            top: -0.6rem;
            left: 0.75rem;
            position: absolute;
        }
        .menu-item {
            padding: $padding-s $padding $padding-s $padding-s;
            border-bottom: $normal-border;
            cursor: pointer;

            &::after {
                content: none;
            }

            .text {
                margin-left: $margin-m;
                text-decoration: none;
                white-space: nowrap;
            }

            &:last-child {
                border: none;
            }
        }
    }
}

@media only screen and (max-width: $screen-mobile) {
    .user-data {
        position: initial !important;
        margin: 0;
        width: 50vw;

        .logo {
            display: none;
        }
        .data {
            width: 50vw;
        }
        .icon-base {
            display: none;
        }
        .user-menu {
            display: none;
        }
    }
}
</style>
