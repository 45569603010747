import { useSettingsStore } from "@/store/Settings"
import i18n from "@/i18n"

// returns currency symbol based on currency code
export const useCurrencySymbol = (currency?: string) => {
    const { locale } = i18n.global
    if (!currency) {
        currency = useSettingsStore().settings.ob_currency
    }

    return getCurrencySymbol(locale.value, currency)
}

// return currency symbol based on locale and currency code
export const getCurrencySymbol = (locale: string, currency: string) => {
    const parts = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).formatToParts(0)
    const currencyPart = parts.find((part) => part.type === "currency")
    return currencyPart ? currencyPart.value : ""
}

// returns currency symbol for current locale
export const useI18nCurrencySymbol = () => {
    const { n, locale } = i18n.global
    return n(0, "currency", locale.value).replace(/\d./g, "").trim()
}

export const useFormatMoney = (amount: number) => {
    const { locale } = i18n.global

    return new Intl.NumberFormat(locale.value, {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "code",
    })
        .format(amount)
        .replace("EUR", "")
        .trim()
}

export const getLocaleByCurrencyCode = (currencyCode: string) => {
    const appLocale = i18n.global.locale.value

    const currencyLocales = [
        { currency: "CHF", locale: "de-CH" },
        { currency: "USD", locale: "en-US" },
    ]

    const euroCountryLocales = [
        { country: "de", locale: "de-DE" },
        { country: "fr", locale: "fr-FR" },
        { country: "nl", locale: "nl-NL" },
    ]

    const locale =
        currencyCode === "EUR"
            ? euroCountryLocales.find((c) => c.country === appLocale)
            : currencyLocales.find((c) => c.currency === currencyCode)

    return locale ? locale.locale : "nl-NL"
}

export const useFormatCurrency = (amount: number, currency?: string) => {
    if (!currency) {
        currency = useSettingsStore().settings.ob_currency
    }

    return new Intl.NumberFormat(getLocaleByCurrencyCode(currency), {
        style: "currency",
        currency: currency,
    }).format(amount)
}
