<template>
    <div
        class="page-header"
        :class="{
            settings: isSettings,
            draggable: RiceCookerPresent,
            'kitchen-screen': isKitchenScreen,
        }"
    >
        <MenuLogo :class="{ settings: isSettings }" />
        <IconBase icon="icon-burger"></IconBase>
        <SettingsMenu v-if="isSettings" />
        <DesktopMainMenu v-else />
        <ControlButtons v-if="RiceCookerPresent"></ControlButtons>
    </div>
    <CallerId />
    <div class="offline top-bar" v-if="showTbExternalOrderApiWarning">
        {{ translate("tb_external_order_api_deprecated_body") }}
    </div>
    <div
        class="top-bar offline"
        v-if="
            offlineModeStore().isOffline ||
            networkIssuesStore().hasNetworkIssues ||
            archive_mode
        "
    >
        <span class="text">
            <IconBase icon="warning" class="mr-2" />
            <b>{{ translate("warning") }}: </b>
            <template v-if="offlineModeStore().isOffline">
                {{ translate("app_offlineMode") }}
            </template>
            <template v-else-if="networkIssuesStore().hasNetworkIssues">
                {{ translate("app_network_issues") }}
            </template>
            <template v-else-if="archive_mode">
                {{ translate("warning_archive_mode") }}
            </template>
        </span>
    </div>
    <div v-if="showFlyerImage" class="shop-background">
        <div class="overlay">
            <h1>{{ translate("order_flyers") }}</h1>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue"
import { useRouter } from "vue-router"
import SettingsMenu from "@/ui-elements/menu/SettingsMenu.vue"
import { useI18n } from "vue-i18n"
import MenuLogo from "@/ui-elements/page-header/MenuLogo.vue"
import ControlButtons from "@/ui-elements/page-header/ControlButtons.vue"
import { RiceCooker } from "@/riceCooker/riceCooker"
import IconBase from "@/ui-elements/IconBase.vue"
import CallerId from "@/ui-elements/page-header/CallerId.vue"
import { useSettingsStore } from "@/store/Settings"
import { useUserStore } from "@/store/User"
import DesktopMainMenu from "@/ui-elements/responsive/desktop/menu/DesktopMainMenu.vue"
import { checkPath } from "@/ui-elements/menu/utils/useCheckPath"
import { offlineModeStore } from "@/store/offlineMode"
import { networkIssuesStore } from "@/store/networkIssues"

export default defineComponent({
    name: "DesktopPageHeader",
    methods: { networkIssuesStore, offlineModeStore },
    components: {
        IconBase,
        MenuLogo,
        SettingsMenu,
        ControlButtons,
        CallerId,
        DesktopMainMenu,
    },
    setup() {
        const { t: translate } = useI18n()
        const router = useRouter()
        const userStore = useUserStore()
        const settingStore = useSettingsStore()
        const showTbExternalOrderApiWarning = computed(
            () =>
                Number(settingStore.settings.tb_api_active) &&
                settingStore.settings.tb_api_version !== "pos_api"
        )

        const isSettings = computed(() => {
            const regex = new RegExp("/settings")
            return (
                (router.currentRoute.value.matched.length &&
                    router.currentRoute.value.matched[0].path ===
                        "/settings") ||
                regex.test(router.currentRoute.value.path)
            )
        })

        const showFlyerImage = computed(
            () => router.currentRoute.value.name === "newFlyer"
        )
        const RiceCookerPresent = RiceCooker.isPresent()

        const archive_mode = computed(
            () => !!Number(userStore.user.is_read_only)
        )

        const isKitchenScreen = computed(() => checkPath("/kitchen-screen"))

        return {
            showFlyerImage,
            translate,
            open,
            isSettings,
            RiceCooker,
            RiceCookerPresent,
            archive_mode,
            showTbExternalOrderApiWarning,
            isKitchenScreen,
        }
    },
})
</script>

<style lang="scss">
.top-bar {
    display: flex;
    color: $white;
    font-weight: $font-weight-medium;
    padding: $padding;
    align-content: center;
    justify-content: center;

    .text,
    .icon-base {
        color: $white;
        font-weight: $font-weight-medium;
    }

    &.caller-id {
        background: $success-color;
    }

    &.offline {
        background: $warning-color;
        padding: $padding-m $padding;
        justify-content: left;

        .text {
            font-weight: normal;

            b {
                font-weight: $font-weight-medium;
            }
        }
    }
    & + .top-bar {
        border-top: 1px solid $faded-primary-color;
    }
}

.page-header {
    background: $white;
    width: 100%;
    display: flex;
    align-items: center;
    padding: $padding $padding-l;
    position: relative;

    &.draggable {
        -webkit-app-region: drag;

        & * {
            -webkit-app-region: no-drag;
        }
    }

    .menu-list,
    .dropdown-menu {
        .menu-item {
            display: flex;
            transition: $quick-transition;
            align-items: center;
            position: relative;

            .icon-base {
                font-size: 1.2rem;
            }

            .text {
                font-size: $font-size-nav;
                line-height: 100%;
                transition: $quick-transition;
            }

            &:link {
                text-decoration: none;
            }

            &:hover {
                color: $darker-gray;

                span {
                    color: $darker-gray;
                    transform: scale(1.1);
                }
                .statistic {
                    color: $primary-color;
                }
            }

            &.current {
                color: $primary-color;

                span {
                    color: $primary-color;
                }
            }

            .statistic {
                border-radius: $radius;
                color: $primary-color;
                text-align: center;
                font-weight: $font-weight-bold;
                position: absolute;
                right: $margin;
                top: 2px;
            }
        }
    }

    .menu-list {
        list-style: none;
        display: flex;
        height: 100%;

        .menu-item {
            padding: 0 $padding-xl;
            justify-content: center;
            flex-flow: column nowrap;
            position: relative;

            &::after {
                position: absolute;
                content: "";
                background: $light-gray;
                width: 1px;
                height: 80%;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        .icon-base {
            margin-bottom: $margin-s;
        }
    }

    &.kitchen-screen {
        padding: $padding-s $padding-l;
    }
}

.shop-background {
    height: 12rem;
    background-image: url("/public/assets/images/shop-background.png");
    background-position: 50% 45%;

    .overlay {
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: $padding-l;
        color: $white;
        background: $transparent-black;
    }
}

.bounce-in-enter-active {
    animation: bounce-in 1s;
}

.bounce-in-leave-active {
    animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

.menu-list-right {
    display: flex;
    align-items: center;
}
</style>
