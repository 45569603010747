import { defineStore } from "pinia"
import axios from "axios"
import { RiceCooker } from "@/riceCooker/riceCooker"
import { websocket } from "@/services/WebsocketService"
import { useSettingsStore } from "@/store/Settings"
import { useMasterSlave } from "@/utils/useMasterSlave"
import { useUserStore } from "@/store/User"

export type POI = {
    default: boolean
    ipAddress: string | null
}

export type PinPaymentStore = {
    currentPoiid: string | null
    serviceId: string | null
    POIs: { [key: string]: POI }
    isCancelling: boolean
    isLoading: boolean
    success: boolean
    errorMessage: string
    pinState: string
}

export const usePinPaymentStore = defineStore({
    id: "pinPayment",
    state: () =>
        ({
            currentPoiid: null,
            serviceId: null,
            POIs: {},
            isCancelling: false,
            isLoading: false,
            success: false,
            errorMessage: "",
            pinState: "",
        } as PinPaymentStore),
    actions: {
        async initiatePayment(
            TransactionID: string,
            amount: number,
            callback?: Function,
            POIID?: string
        ) {
            const settingsStore = useSettingsStore()
            this.clearPayment()

            this.serviceId = Math.random().toString(36).substring(2, 12)
            const obId = this.getObId()
            this.currentPoiid =
                POIID ||
                localStorage.getItem("adyen_pin_poiid_local") ||
                settingsStore.settings.adyen_pin_poiid

            // listen on ws channel
            if (callback) {
                websocket.echo
                    ?.channel(this.getWsChannel())
                    .listen(".display", callback)
            }

            console.log(
                "SentryDevInfo: PinPayment.ts POST client/pos/adyen/init data",
                {
                    transaction_id: TransactionID,
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: obId,
                    amount,
                }
            )

            return await axios.post(
                "client/pos/adyen/init",
                {
                    transaction_id: TransactionID,
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: obId,
                    amount,
                },
                {
                    timeout: 1000 * (60 * 2), // need 2 minutes for adyen to handle the request
                }
            )
        },

        async verify(
            orderId?: number,
            ignoreStatus: boolean = false
        ): Promise<any> {
            if (!this.serviceId) {
                return null
            }

            // todo: RC verify

            console.log(
                "SentryDevInfo: PinPayment.ts POST client/pos/adyen/verify data",
                {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                    order_id: orderId || null,
                    ignore_status: ignoreStatus,
                }
            )

            return (
                await axios.post("client/pos/adyen/verify", {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                    order_id: orderId || null,
                    ignore_status: ignoreStatus,
                })
            ).data
        },

        async abort(): Promise<boolean> {
            if (!this.serviceId) {
                return true
            }

            try {
                this.isCancelling = true

                console.log(
                    "SentryDevInfo: PinPayment.ts POST client/pos/adyen/cancel data",
                    {
                        service_id: this.serviceId,
                        poiid: this.currentPoiid,
                        sale_id: this.getObId(),
                    }
                )

                await axios.post("client/pos/adyen/cancel", {
                    service_id: this.serviceId,
                    poiid: this.currentPoiid,
                    sale_id: this.getObId(),
                })
            } finally {
                this.isCancelling = false
            }

            return true
        },

        clearPayment() {
            websocket.echo?.leaveChannel(this.getWsChannel())
            this.serviceId = null
            this.currentPoiid = null
        },

        getWsChannel(): string {
            return `adyen.${this.currentPoiid}.${this.serviceId}`
        },

        getObId() {
            return `${useUserStore().user.id}-${useMasterSlave().obId}`
        },
        resetPaymentStatuses() {
            this.success = false
            this.errorMessage = ""
        },
    },
})
