<template>
    <DeviceCheck>
        <template v-slot="{ isMobile }">
            <div v-if="!isMobile" class="device-check-wrapper">
                <FloatingModal
                    :title="`${translate('data')}: #${user.id} | ${
                        user.address.company
                    }`"
                    :submitButtonText="translate('close')"
                    @submit="onSubmit"
                    :show="adminInfoModalOpen"
                    class="client-info grid-cols-10"
                >
                    <div class="description">
                        <AdminClientStatistics />
                        <h3 class="title">{{ translate("miscellaneous") }}</h3>
                        <BaseTable
                            :selectable="false"
                            :deletable="false"
                            class="mb-4"
                        >
                            <template #list-header>
                                <TableCell v-if="riceCookerVersion">
                                    {{ translate("orderbuddy_desktop") }}
                                </TableCell>
                                <TableCell v-if="devGetFrontendVersion()">
                                    {{ translate("orderbuddy_web") }}
                                </TableCell>
                                <TableCell>
                                    {{ translate("prizewheel") }}
                                </TableCell>
                                <TableCell
                                    v-if="
                                        adminInfo?.is_part_of !== undefined &&
                                        adminInfo?.is_part_of.type !== 'none'
                                    "
                                >
                                    {{
                                        translate(
                                            adminInfo.is_part_of.type ===
                                                "multi_kitchen"
                                                ? "multi_kitchen_of"
                                                : "multi_branch_of"
                                        )
                                    }}
                                </TableCell>
                                <TableCell v-if="adminInfo?.account_manager">
                                    {{ translate("accountmanager") }}
                                </TableCell>
                            </template>
                            <template #list>
                                <TableRow :selectable="false">
                                    <TableCell v-if="riceCookerVersion">
                                        {{ riceCookerVersion }}
                                    </TableCell>
                                    <TableCell v-if="devGetFrontendVersion()">
                                        {{ devGetFrontendVersion() }}
                                    </TableCell>
                                    <TableCell
                                        :class="[
                                            adminInfo?.is_prizewheel_active
                                                ? 'active'
                                                : 'inactive',
                                        ]"
                                    >
                                        {{
                                            adminInfo?.is_prizewheel_active
                                                ? translate("active")
                                                : translate("inactive")
                                        }}
                                    </TableCell>
                                    <TableCell
                                        v-if="
                                            adminInfo?.is_part_of !==
                                                undefined &&
                                            adminInfo?.is_part_of.type !==
                                                'none'
                                        "
                                    >
                                        {{ adminInfo.is_part_of.locations }}
                                        &nbsp;(#{{
                                            adminInfo.is_part_of.parent_ids.join(
                                                ", #"
                                            )
                                        }})
                                    </TableCell>
                                    <TableCell
                                        v-if="adminInfo?.account_manager"
                                    >
                                        {{ adminInfo.account_manager }}
                                    </TableCell>
                                </TableRow>
                            </template>
                        </BaseTable>
                        <h3 class="title" v-if="adminInfo?.todos?.length > 0">
                            Todos
                        </h3>
                        <BaseTable
                            :selectable="false"
                            :deletable="false"
                            class="mb-4"
                            v-if="adminInfo?.todos?.length > 0"
                        >
                            <template #list-header>
                                <TableCell> # </TableCell>
                                <TableCell>
                                    {{ translate("subject") }}
                                </TableCell>
                                <TableCell> Prio </TableCell>
                                <TableCell>
                                    {{ translate("status") }}
                                </TableCell>
                                <TableCell>
                                    {{ translate("assignee") }}
                                </TableCell>
                                <TableCell>
                                    {{ translate("actions") }}
                                </TableCell>
                            </template>
                            <template #list>
                                <TableRow
                                    v-for="todo in adminInfo?.todos"
                                    :key="todo.id"
                                    :selectable="false"
                                    @click="openTodoInNewTab(todo.id)"
                                >
                                    <TableCell>{{ todo.id }}</TableCell>
                                    <TableCell>{{
                                        todo.subject_description
                                    }}</TableCell>
                                    <TableCell>{{
                                        todo.prio_description
                                    }}</TableCell>
                                    <TableCell>{{
                                        todo.status_description
                                    }}</TableCell>
                                    <TableCell>{{ todo.assignee }}</TableCell>
                                    <TableCell
                                        ><IconBase icon="link"
                                    /></TableCell>
                                </TableRow>
                            </template>
                        </BaseTable>
                        <template v-if="adminInfo?.invoices?.length > 0">
                            <h3 class="title">{{ translate("invoices") }}</h3>
                            <AdminInvoicesTable
                                :invoices="adminInfo.invoices"
                            />
                        </template>
                        <h3 class="title">{{ translate("remarks") }}</h3>
                        <TextArea :disabled="true" :text="adminInfo?.remarks" />
                    </div>
                </FloatingModal>
            </div>
        </template>
    </DeviceCheck>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent } from "vue"
import { useI18n } from "vue-i18n"
import BaseTable from "@/ui-elements/layout/tables/BaseTable.vue"
import TableCell from "@/ui-elements/layout/tables/TableCell.vue"
import TableRow from "@/ui-elements/layout/tables/TableRow.vue"
import TextArea from "@/ui-elements/input/text-area/TextArea.vue"
import IconBase from "@/ui-elements/IconBase.vue"
import { useUserStore } from "@/store/User"
import FloatingModal from "@/ui-elements/layout/modals/FloatingModal.vue"
import AdminInvoicesTable from "@/app/admin-client-info-modal/AdminInvoicesTable.vue"
import AdminClientStatistics from "@/app/admin-client-info-modal/AdminClientStatistics.vue"
import { User } from "@/interface/user/User"
import DeviceCheck from "@/utils/responsive/DeviceCheck.vue"
import { checkPath } from "@/utils/useCheckPath"
import {
    devGetFrontendVersion,
    devGetRiceCookerVersion,
} from "@/utils/devUtils"
const riceCookerVersion = await devGetRiceCookerVersion()

export default defineComponent({
    name: "AdminClientInfoModal",
    methods: { devGetFrontendVersion },
    components: {
        DeviceCheck,
        BaseTable,
        TableCell,
        TableRow,
        TextArea,
        IconBase,
        FloatingModal,
        AdminInvoicesTable,
        AdminClientStatistics,
    },
    setup() {
        const { t: translate } = useI18n()
        const userStore = useUserStore()

        const adminInfoModalOpen = computed(
            () =>
                userStore.admin_info_modal_open && !checkPath("customer-screen")
        )

        const user = computed(() => userStore.user)

        const adminInfo: ComputedRef<User["admin_info"]> = computed(
            () => userStore?.user?.admin_info
        )

        console.log(adminInfo.value)

        const onSubmit = () => {
            if (userStore.admin_info_modal_open) {
                userStore.toggleAdminInfoModal()
            }
        }

        const openTodoInNewTab = (todoId: number) =>
            window
                .open(
                    `https://www.foodticket.nl/foodticket/cgi/beheer/admin.cgi?action=edit-todo&id=${todoId}`,
                    "_blank"
                )
                ?.focus()

        return {
            translate,
            user,
            adminInfo,
            adminInfoModalOpen,
            onSubmit,
            openTodoInNewTab,
            riceCookerVersion,
        }
    },
})
</script>

<style lang="scss">
.floating-modal .panel-content {
    height: 100%;

    .body {
        height: 100%;
        overflow-y: auto;
    }
}

.client-info {
    grid-template-rows: 4.25rem minmax(0, 1fr) 4.75rem;
    row-gap: $margin-s;
    .floating-modal {
        grid-column: 8 / span 3;
        grid-row: 2;
        height: 100%;
    }

    .description {
        overflow-y: auto;
        font-size: $font-size-base;
        color: $secondary-color;
        padding-bottom: $margin-xl;

        .title {
            color: $secondary-color;
            margin: 0 0 $margin-m $margin-s;
        }
        .text-area {
            width: unset;
            margin: 0 $margin-s;
        }
        .table-cell {
            padding: $padding-xs $padding-s;
        }
    }

    .overdue {
        background-color: $transparent-warning;
    }

    .inactive,
    .longOverdue {
        background-color: $transparent-error;
    }

    .active {
        background-color: $transparent-green;
    }
}
</style>
