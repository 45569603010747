import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-options"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTable = _resolveComponent("BaseTable")!
  const _component_PanelComponent = _resolveComponent("PanelComponent")!

  return (_openBlock(), _createBlock(_component_PanelComponent, {
    id: "table-component",
    pagination: _ctx.pagination,
    "onUpdate:pagination": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:pagination', $event))),
    "show-pagination": _ctx.showPagination,
    "show-total-count": _ctx.showTotalCount,
    scrollAreaEl: _ctx.scrollArea,
    ready: _ctx.ready
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar"),
      (_ctx.showFiltersPanel)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "filters-panel")
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_BaseTable, {
        loading: _ctx.loading,
        selectable: _ctx.selectable,
        deletable: _ctx.deletable,
        "selection-object": _ctx.selectionObject,
        empty: _ctx.empty,
        isDraggable: _ctx.isDraggable
      }, {
        "list-header": _withCtx(() => [
          _renderSlot(_ctx.$slots, "list-header")
        ]),
        list: _withCtx(() => [
          _renderSlot(_ctx.$slots, "list")
        ]),
        "no-rows": _withCtx(() => [
          _renderSlot(_ctx.$slots, "no-rows")
        ]),
        _: 3
      }, 8, ["loading", "selectable", "deletable", "selection-object", "empty", "isDraggable"])
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "list-footer")
    ]),
    _: 3
  }, 8, ["pagination", "show-pagination", "show-total-count", "scrollAreaEl", "ready"]))
}