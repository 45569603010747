import { defineStore } from "pinia"
import {
    SplitPaymentPerson,
    SplitPaymentProduct,
    SplitPaymentSplitType,
    SplitPaymentStep,
} from "@/interface/pos/SplitPaymentPerson"
import { usePOSStore } from "@/store/POS"
import axios from "axios"
import { translate } from "printhtmlv3/dist/lang"
import { useCartStore } from "@/store/cart/Cart"
import { Order } from "@/interface/orders/Order"

export type SplitPaymentState = {
    step: SplitPaymentStep
    splitType: SplitPaymentSplitType
    personsCount: number
    persons: SplitPaymentPerson[]
    modalProducts: SplitPaymentProduct[]
    selectedPersonIndex: number
    orderId: number
    order: Order | null
    activePaymentIndex: number
    roundingDifference: number
}

export const useSplitPaymentStore = defineStore({
    id: "splitPayment",
    state: () => getInitialSplitPaymentStore() as SplitPaymentState,
    getters: {
        selectedPerson: (state) => {
            return state.persons[state.selectedPersonIndex]
        },
        isPartiallyPaid: (state) => {
            // Feels counterintuitive, but partially paid is only true if there's at least one person that paid and at
            // least one person that hasn't paid yet. If no one paid yet, it's false.
            return (
                state.persons.some(
                    (person: SplitPaymentPerson) => person.paid
                ) &&
                state.persons.some((person: SplitPaymentPerson) => !person.paid)
            )
        },
        numberOfPeopleToPay: (state) => {
            return state.persons.filter(
                (person: SplitPaymentPerson) => !person.paid
            ).length
        },
    },
    actions: {
        closeProductsModal() {
            const posStore = usePOSStore()
            posStore.setCartOverlay(true)
            posStore.openedModal = ""
            this.selectedPersonIndex = -1
            this.modalProducts = []
        },
        clearState() {
            this.$state = getInitialSplitPaymentStore()
            localStorage.setItem("splitPaymentOrder", "")
            this.closeOverlays()
        },
        closeOverlays() {
            const posStore = usePOSStore()
            posStore.openedModal = null
            posStore.posComponent = "POSMenu"
            posStore.setToolsOverlay(false)
            posStore.setCartOverlay(false)
        },
        showOverlays() {
            const posStore = usePOSStore()
            posStore.openedModal = ""
            posStore.setToolsOverlay(true)
            posStore.setCartOverlay(true)
        },
        saveChanges() {
            if (!this.orderId) {
                throw new Error(translate("please_try_again"))
            }
            return axios.post("/client/orders/splitPayment/" + this.orderId, {
                state: { ...this.$state, activePaymentIndex: undefined },
            })
        },
        loadOrderId() {
            this.orderId = Number(localStorage.getItem("splitPaymentOrder"))
            return this.orderId
        },
        async restoreState() {
            if (!this.orderId) {
                return
            }

            const cartStore = useCartStore()
            const posStore = usePOSStore()

            try {
                await cartStore.loadCart(this.orderId)
                const response = await axios.get(
                    "/client/orders/splitPayment/" + this.orderId
                )
                this.$state = getInitialSplitPaymentStore(
                    response.data.data.state
                )
                // open split payments modal
                this.showOverlays()
                posStore.posComponent = "SplitPayment"
                if (this.modalProducts.length) {
                    posStore.openedModal = "split-payment-products"
                }
            } catch (e: any) {
                this.setOrderId(0)
                throw new Error(e.message || e)
            }
        },
        setOrderId(orderId: number) {
            this.orderId = orderId
            localStorage.setItem("splitPaymentOrder", String(orderId))
        },
    },
})

const getInitialSplitPaymentStore = (data: Record<string, any> = {}) => {
    return {
        step: data.step || SplitPaymentStep.Number,
        splitType: data.splitType || SplitPaymentSplitType.Amount,
        personsCount: data.personsCount || 2,
        persons: data.persons || [],
        modalProducts: data.modalProducts || [],
        selectedPersonIndex:
            data.selectedPersonIndex >= 0 ? data.selectedPersonIndex : -1,
        orderId: data.orderId || 0,
        order: data.order || null,
        activePaymentIndex:
            data.activePaymentIndex >= 0 ? data.activePaymentIndex : -1,
        roundingDifference: 0,
    }
}
