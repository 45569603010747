import i18n from "@/i18n"
import { defineStore } from "pinia"
import axios from "axios"
import { useCartStore } from "@/store/cart/Cart"
import { Category } from "@/interface/menu/Category"
import { Extra, ExtraItem } from "@/interface/menu/Extra"
import {
    ExtraDependency,
    KitchenMenus,
    MultiKitchenMenus,
} from "@/interface/menu/Menu"
import { CategoryProductRelation, Product } from "@/interface/menu/Product"
import { CartItem } from "@/interface/Cart"
import { useSettingsStore } from "@/store/Settings"
import { multiLocationStore } from "@/store/MultiLocation"

export type PosMenuPageType = "categories" | "products"

export type PosMenusStoreType = {
    isLoading: boolean
    menus: KitchenMenus | MultiKitchenMenus
    selectedMenuId: number
    selectedCategoryId: number
    selectedSubCategoryId: number
    selectedCategories: number[]
    selectedProducts: number[]
    categories: Map<number, Category>
    products: Map<number, Product>
    productCategories: Map<number, CategoryProductRelation[]>
    extras: Map<number, Extra>
    extra_dependencies: Map<number, ExtraDependency>
    items: Map<number, ExtraItem>
    searchText: string
    loaded: boolean
    // this is needed for the extras modal
    selectedProductForExtras: {
        id: number
        discountId: number
    }
    selectedCartProductIndex: number
    activePage: PosMenuPageType
}

interface SelectProductParams {
    id: number
    items?: CartItem[]
    remarks?: string
    quantity?: number | null
    discountId?: number
}

export const DEFAULT_MENU_ID = 1

export const usePosMenusStore = defineStore({
    id: "posMenus",
    state: () => {
        return {
            isLoading: false,
            menus: {
                dinein: {
                    id: 2,
                    categories: [],
                    products: [],
                    pinned_categories: [],
                    extras: [],
                },
                deliver: {
                    id: 1,
                    categories: [],
                    products: [],
                    pinned_categories: [],
                    extras: [],
                },
                pickup: {
                    id: 0,
                    categories: [],
                    products: [],
                    pinned_categories: [],
                    extras: [],
                },
            },
            activePage: "categories",
            selectedMenuId:
                Number(useSettingsStore().settings.counter_menu) ??
                DEFAULT_MENU_ID,
            selectedCategoryId: 0,
            selectedSubCategoryId: 0,
            selectedProducts: [],
            selectedCategories: [],
            categories: new Map(),
            products: new Map(),
            productCategories: new Map(),
            extras: new Map(),
            extra_dependencies: new Map(),
            items: new Map(),
            searchText: "",
            loaded: false,
            selectedProductForExtras: {
                id: 0,
                discountId: 0,
            },
            selectedCartProductIndex: -1,
        } as PosMenusStoreType
    },
    getters: {
        pinnedCategoryIds: (state): number[] => {
            return (
                Object.values(state.menus ?? {}).find(
                    (menu) => menu.id === state.selectedMenuId
                )?.pinned_categories || []
            )
        },
        category:
            (state) =>
            (id: number): Category | undefined => {
                return state.categories?.get(id)
            },
        product:
            (state) =>
            (id: number): Product | undefined => {
                return state.products?.get(id)
            },
        product_categories:
            (state) =>
            (id: number): CategoryProductRelation[] | undefined => {
                return state.productCategories?.get(id)
            },
        extra:
            (state) =>
            (id: number): Extra | undefined => {
                return state.extras?.get(id)
            },
        extra_dependency:
            (state) =>
            (id: number): ExtraDependency | undefined => {
                return state.extra_dependencies?.get(id)
            },
        item:
            (state) =>
            (id: number): ExtraItem | undefined => {
                return state.items?.get(id)
            },
        mainCategoryIds: (state) => {
            const categoryIds =
                Object.values(state.menus ?? {}).find(
                    (menu) => menu.id === state.selectedMenuId
                )?.categories || []
            // Leave only categories that are not subcategories
            return categoryIds.filter((categoryId) => {
                const category = state.categories?.get(categoryId)
                if (!category) {
                    return false
                }
                return !category.parent_id && !category.has_runners
            })
        },
        categoryIds: (state) => {
            return (
                Object.values(state.menus ?? {}).find(
                    (menu) => menu.id === state.selectedMenuId
                )?.categories || []
            )
        },
        selectedProduct: (state): Product | undefined => {
            return state.products?.get(state.selectedProductForExtras.id)
        },
        pinnedProductIds:
            (state) =>
            (categoryId: number): number[] => {
                const productIds =
                    state.categories.get(categoryId)?.product_ids || []
                return productIds.filter((productId) => {
                    const categoryProducts =
                        state.products?.get(productId)?.category_products || []
                    const categoryRelation = categoryProducts.find(
                        (categoryProduct) =>
                            categoryProduct.category_id === categoryId
                    )
                    return !!categoryRelation?.static_counter
                })
            },
        menuOptions:
            () =>
            (useStringTypeNames: boolean = false) => {
                if (multiLocationStore().isActive) {
                    return multiLocationStore().menusForDropdown
                }

                const { t: translate } = i18n.global
                const availableMenus = useSettingsStore().settings.menus
                const numberToMenuKey = {
                    "0": "pickup",
                    "1": "deliver",
                    "2": "dinein",
                } as Record<string, string>
                const numberToTranslationString = {
                    "0": "takeaway",
                    "1": "default",
                    "2": "restaurant",
                } as Record<string, string>
                return Object.keys(availableMenus).map((menuKey) => {
                    return {
                        value: useStringTypeNames
                            ? numberToMenuKey[menuKey]
                            : menuKey,
                        label: translate(
                            menuKey === "1"
                                ? availableMenus[menuKey]
                                : numberToTranslationString[menuKey]
                        ),
                    }
                })
            },
    },
    actions: {
        async fetchMenus(): Promise<boolean> {
            if (multiLocationStore().isActive) {
                multiLocationStore().swapMenu(
                    multiLocationStore().selected_location_id_for_menu +
                        "-" +
                        this.selectedMenuId
                )

                this.loaded = true

                return true
            } else {
                try {
                    const response = await axios.get("client/pos/menu", {
                        baseURL: axios.defaults.baseURL?.replace("v1", "v2"),
                    })
                    const data = response.data.data
                    this.menus = data?.menus

                    this.categories = data?.categories.reduce(
                        (
                            accumulator: Map<number, Category>,
                            category: Category
                        ) => {
                            accumulator.set(category.id, category)
                            return accumulator
                        },
                        new Map()
                    )

                    this.products = data?.products.reduce(
                        (
                            accumulator: Map<number, Product>,
                            product: Product
                        ) => {
                            accumulator.set(product.id, product)
                            return accumulator
                        },
                        new Map()
                    )

                    this.productCategories = Object.keys(
                        data?.product_categories
                    ).reduce((accumulator, key) => {
                        accumulator.set(
                            Number(key),
                            data?.product_categories[key]
                        )
                        return accumulator
                    }, new Map())

                    this.extras = data?.extras.reduce(
                        (accumulator: Map<number, Extra>, extra: Extra) => {
                            accumulator.set(extra.id, extra)
                            return accumulator
                        },
                        new Map()
                    )

                    this.extra_dependencies = data.extra_dependencies.reduce(
                        (
                            accumulator: Map<number, ExtraDependency>,
                            extraDependency: ExtraDependency
                        ) => {
                            accumulator.set(
                                extraDependency.extra_id,
                                extraDependency
                            )
                            return accumulator
                        },
                        new Map()
                    )

                    this.items = data?.items.reduce(
                        (
                            accumulator: Map<number, ExtraItem>,
                            item: ExtraItem
                        ) => {
                            accumulator.set(item.id, item)
                            return accumulator
                        },
                        new Map()
                    )

                    this.loaded = true

                    return true
                } catch (e) {
                    return false
                }
            }
        },
        selectCategory(id: number) {
            this.selectedCategoryId = id
        },
        selectSubCategory(id: number) {
            this.selectedSubCategoryId = id
        },
        saveCategory(category: Category) {
            this.categories.set(category.id, category)
        },
        saveProduct(product: Product) {
            this.products.set(product.id, product)
        },
        selectMenu(value: keyof KitchenMenus) {
            const settingsStore = useSettingsStore()
            this.resetSelection()

            const menus = Object.keys(settingsStore.settings.menus)
            const newMenu = menus.findIndex((key) => key === value)

            // Index can be 0. If not found, newMenu will be -1. Hence it is smaller than 0, so replace with default
            this.selectedMenuId =
                newMenu < 0 ? DEFAULT_MENU_ID : Number(menus[newMenu])
        },
        selectProduct({
            id,
            items = [],
            remarks = "",
            quantity = null,
            discountId = 0,
        }: SelectProductParams) {
            if (!this.products.has(id)) {
                return
            }

            const product = this.products.get(id)!
            const category = this.categories.get(product.main_category_id!)

            if (category) {
                product.category = category
            }

            const cartStore = useCartStore()
            const props = {
                product: product,
                items: items,
                remarks: remarks,
                quantity: quantity || 1,
                discountId,
            }
            cartStore.addProduct(props)
        },
        resetSelection(menuId?: number) {
            const settingsStore = useSettingsStore()

            if (multiLocationStore().isActive && menuId !== undefined) {
                this.selectedMenuId = menuId
            } else {
                this.selectedMenuId =
                    Number(settingsStore.settings.counter_menu) ??
                    DEFAULT_MENU_ID
            }

            this.activePage = Number(settingsStore.settings.pos_onload_products)
                ? "products"
                : "categories"
            this.selectedCategoryId = 0
            this.selectedSubCategoryId = 0
            this.selectedCategories = []
            this.selectedProducts = []
            this.searchText = ""
        },
        resetSelectedProduct() {
            this.selectedCartProductIndex = -1
            this.selectedProductForExtras = {
                id: 0,
                discountId: 0,
            }
        },
        setMenu(
            menus: KitchenMenus,
            categories: Map<number, Category>,
            products: Map<number, Product>,
            productCategories: Map<number, CategoryProductRelation[]>,
            extras: Map<number, Extra>,
            extra_dependencies: Map<number, ExtraDependency>,
            items: Map<number, ExtraItem>,
            menuId: number
        ) {
            this.resetSelection(menuId)

            this.menus = menus
            this.categories = categories
            this.products = products
            this.productCategories = productCategories
            this.extras = extras
            this.extra_dependencies = extra_dependencies
            this.items = items
        },
    },
})
