<template>
    <PopUpModal
        show
        :title="
            noCommission
                ? translate('delete_no_commission')
                : translate('delete_order')
        "
        @close="$emit('close')"
        @submit="deleteOrder"
        class="delete-order"
        :submitButtonText="translate('confirm')"
    >
        <span v-html="translate('archive_edit_delete_popup_description')" />
        <div class="inputs">
            <FormErrorsProvider :errors="formErrors">
                <TextField
                    type="text"
                    v-model:text="reason"
                    :label="translate('reason')"
                    name="reason"
                />
                <TextField
                    type="password"
                    v-model:text="pin"
                    label="PIN"
                    v-if="!isAdmin"
                    name="pincode"
                    autocomplete="new-password"
                />
            </FormErrorsProvider>
        </div>
    </PopUpModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import { PopUpModal } from "@/ui-elements"
import { useI18n } from "vue-i18n"
import TextField from "@/ui-elements/input/text-field/TextField.vue"
import axios from "axios"
import { useRouter } from "vue-router"
import FormErrorsProvider from "@/ui-elements/FormErrorsProvider.vue"
import { useFormErrors } from "@/utils/useFormErrors"
import { useUserStore } from "@/store/User"
import { ApiError } from "@/interface/api/ApiError"
import { setToastNotification } from "@/ui-elements/toast-notification/setToastNotification"
export default defineComponent({
    name: "OrderDeletePopUp",
    components: {
        FormErrorsProvider,
        PopUpModal,
        TextField,
    },
    props: {
        id: Number,
        noCommission: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const { t: translate } = useI18n()
        const reason = ref("")
        const pin = ref("")
        const userStore = useUserStore()
        const isAdmin = computed(() => !!Number(userStore.user.is_admin))

        const { formErrors, handleApiFormErrors, clearFormErrors } =
            useFormErrors()

        const deleteOrder = async () => {
            clearFormErrors()
            axios
                .delete("/client/archive/" + props.id, {
                    data: {
                        pincode: pin.value,
                        reason: reason.value,
                        no_commission: props.noCommission,
                    },
                })
                .then(() => {
                    context.emit("success")
                    context.emit("close")
                })
                .catch((error) => {
                    const errorCode: ApiError = error.response.data.error
                    if (errorCode === ApiError.READONLY) {
                        context.emit("close")
                        setToastNotification(
                            translate("something_went_wrong"),
                            error.response.data.message,
                            "danger"
                        )
                        return
                    }
                    handleApiFormErrors(error)
                })
        }

        return {
            translate,
            reason,
            pin,
            deleteOrder,
            formErrors,
            isAdmin,
        }
    },
    emits: ["close", "success"],
})
</script>

<style lang="scss">
.delete-order {
    display: flex;
    flex-flow: column nowrap;

    .popup-content {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        flex: 1 1;
    }

    .inputs {
        padding-top: $padding;
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1;
        gap: $padding;
    }
}
</style>
