import { OrderSourceType } from "@/interface/orders/Order"

export enum DispatchType {
    None = "",
    Deliver = "deliver",
    PickUp = "pickup",
    EatIn = "dinein",
    PickUpOnly = "pickup_only",
    DeliverOnly = "deliver_only",
}

type deliveryFeeConditions = {
    free_from: number
    minimum: number
    zipcode_id: number | null
}

export type CartDiscount = {
    type: "custom" | "code" | "wigroup" | "external"
    code?: string // used for both type=code en code=wigroup
    custom?: {
        type: "absolute" | "percentage"
        description?: string // only present when type=custom
        amount?: number // only present when type=custom
    }
}

export type CartProduct = {
    id: number // -3 = custom product
    order_product_id?: number // for exising order products
    title?: string // only for custom product
    price?: number // only for custom product,
    discount?: number // discount applied to this product
    quantity?: number // defaults to 1
    deposit?: number // only for custom product
    total?: number // response
    total_original?: number // response
    total_extras?: number // response
    vat: {
        rate: number
        amount?: number
    }
    items?: CartItem[]
    remarks?: string
    discount_id?: number
    new?: boolean
    customId?: number
    packaging_costs: number | null
    category_packaging_costs: number | null
    is_free: boolean
}

export type CartFreeProduct = CartProduct & {
    discount_id: string
}

export type CartItem = {
    id: number
    extra_id: number
    product_id?: number | null
    quantity?: number // defaults to 1
    total?: number

    packaging_costs: number | null
}

export type Cart = {
    order_id?: number
    products: CartProduct[]
    free_products: CartFreeProduct[]
    tip?: number | null
    type: DispatchType
    points?: number
    zip_code?: string
    schedule: {
        time?: string
        date: string
        asap: boolean
    }
    discounts: CartDiscount[]
    payment_method: string | null
    payment2?: {
        method: string
        amount: number
    }
    remarks: string
    additional_delivery_cost: number
    products_can_be_updated: boolean
}

export enum DiscountType {
    SIMPLE = "simple",
    DISCOUNTED_PRODUCT = "discounted_product",
    FREE_PRODUCT = "free_product",
    CUSTOM = "custom",
    EXTERNAL = "external",
}

export type DiscountAmountType =
    | "absolute"
    | "percentage"
    | "points"
    | "set_price"
    | "no_delivery_costs"
    | "merge_price"

export type OutputDiscount = {
    id?: number
    label?: string
    code?: string
    type: DiscountType
    amount_type: DiscountAmountType
    amount: number | null
    customer_exceeds_maximum_usage?: boolean
    external_id?: string | null
}

export type OutputVatRate = {
    amount: number // amount of vat charged
    total: number // over this total
}

export type OutputChoice = {
    label: string | null
    description?: string | null
    product_ids: number[]
    count: number | null // null=all products are free
    discount: DiscountFreeProduct
}

export type DiscountFreeProduct = {
    id: number
    label: string | null // code_str
    from_amount: number | null // null = always
    availability: {
        days: string[] | null
        time_start: string | null
        time_end: string | null
    }
    dispatch_types: DispatchType[]
    sources: OrderSourceType[]
    discount: {
        apply_count: number // how many times this discount may be applied per order (max)
        free_products_count: number // number of free products (use only if is_all_free=false)
        is_all_free: boolean // are all product_ids free?
        product_ids: number[] // products that are free
        required_product_ids?: number[] // products required for this discount to apply, only discountproductsproducts
        nth_product?: number // apply the discount to every n-th product (discountproductsproducts.nn)
        include_extras?: boolean // when setting a price, ignore additional price on extras, only discountproductsproducts
    }
    codes: string[]
}

export type CalculatedCart = {
    cart: Cart
    delivery_fee_conditions: deliveryFeeConditions
    costs: {
        payment: number
        delivery: number
        packaging: number
        deposit: number
        tip: number
    }
    choices: OutputChoice[] // user can choose free product
    discounts: OutputDiscount[]
    vat: {
        rates: {
            [key: number]: OutputVatRate
        }
        total: number
    }
    points: {
        spent: number
        earned: number
    }
    discount: number
    subtotal: number // products (excluding costs & discounts)
    total: number // (subtotal + delivery - discounts) + deposit + tip + payment costs
    total_rounded?: number | null
    cash_amount?: number | null
    table_id: number
    is_loaded_from_order: boolean
    _log: object
}
