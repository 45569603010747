<template>
    <div class="tag" :class="[`tag-${tagStyle}`, { smallTag: smallTag }]">
        <slot name="tagContent" />
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"

export type TagStyleType =
    | "warning"
    | "error"
    | "success"
    | "secondary"
    | "light"
    | "gray"
    | "info"

export default defineComponent({
    name: "Tag",
    props: {
        tagStyle: {
            type: String as PropType<TagStyleType>,
            default: "secondary",
        },
        smallTag: {
            type: Boolean,
        },
    },
})
</script>

<style lang="scss" scoped>
.tag {
    display: inline-block;
    border-radius: $radius;
    padding: $padding-xs $padding-s;
    font-size: $font-size-base;

    &.smallTag {
        font-size: $font-size-nav;
        display: flex;
        align-items: center;
    }

    &.tag-warning {
        background: $transparent-primary;
        color: $warning-color;
        border: $warning-border;

        :deep(div),
        :deep(span) {
            color: $warning-color;
        }
    }

    &.tag-error {
        background: $transparent-error;
        color: $error-color;
        border: $error-border;

        :deep(div),
        :deep(span) {
            color: $error-color;
        }
    }

    &.tag-success {
        background: $transparent-green;
        color: $success-color;
        border: $success-border;

        :deep(div),
        :deep(span) {
            color: $success-color;
        }
    }

    &.tag-info {
        background: $transparent-info;
        color: $info-color;
        border: $info-border;

        :deep(div),
        :deep(span) {
            color: $info-color;
        }
    }

    &.tag-secondary {
        background: $secondary-color;
        color: $white;
        border: $important-border;

        :deep(div),
        :deep(span) {
            color: $white;
        }
    }

    &.tag-light {
        background: $white;
    }

    &.tag-gray {
        background: $light-gray;
    }
}
</style>
